const BASE_URL: string = process.env.VUE_APP_API_BASE_URL as string

interface AppApiConfig {
  [key: string]: {
    apiFamily: string
    version: string
    apiSegment: string
    description: string
  }
}

const appConfig: AppApiConfig = {
  USER_LOGIN: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: '',
    description: 'User login',
  },
  USER_REFRESH: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: '',
    description: 'User refresh login',
  },
  UPDATE_PASSWORD_WITH_CODE: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: '',
    description: 'User update password',
  },
  SSO_VALIDATE: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: '',
    description: 'SSO Validate',
  },
  SSO_LOGIN: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: '',
    description: 'SSO Login',
  },
  FORGOT_PASSWORD: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: '',
    description: 'User forgot password request',
  },
  EMP_GRANTS: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: '',
    description: 'User grants',
  },
  DEST_PET_DAILY: {
    apiFamily: 'destpetdaily/',
    version: 'v1/',
    apiSegment: '',
    description: 'Daily payments collected',
  },
  CENTERS_LIST: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: '',
    description: 'List of centers',
  },
  CENTERS_LIST_USER_ACCESSIBLE: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centers-accessible/',
    description: 'List of centers the current user has access to',
  },
  CENTER_MAIN: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centers/',
    description: 'Main center data content',
  },
  CENTER_CUSTOM_HOURS: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centers/customHours',
    description: 'Main center data custom Hours',
  },
  CENTER_MARKETING: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centermarketing/',
    description: 'Marketing related data content per center',
  },
  CENTER_LOCATION: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centerlocation/',
    description: 'Facility-location related data content per center',
  },
  CENTER_OPERATIONS: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centeroperations/',
    description: 'Operations related data content per center',
  },
  CENTER_CONTACTS: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centercontacts/',
    description: 'Center contacts data',
  },
  CENTER_CONTACTS_TITLES: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'contact/title',
    description: 'Center contacts titles data',
  },
  CENTER_CONTACTS_DELETE: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'center/',
    description: 'Center contacts data',
  },
  CENTER_DATA_SOURCE: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'datasources/',
    description: 'Data Sources related data content per center',
  },
  CENTER_SERVICES: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centerservices/',
    description: 'Center services related data content per center',
  },
  CENTER_NOTES: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centernotes/',
    description: 'Center notes data',
  },
  CENTER_REGIONS: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'regions',
    description: 'Center Regions List',
  },
  CENTER_MARKETING_YOURGI: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centerappointmenttypehours/',
    description: 'Center Marketing Yourgi List',
  },
  CENTER_LODGING_DETAILS: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'center/${centerId}/lodging',
    description: 'Center Lodging Details',
  },
  APP_CONTACTS: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'contacts/',
    description: 'List of Contacts',
  },
  CONTACTS_REGIONS: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'regioncontacts/',
    description: 'List of Contacts Regions',
  },
  CONTACTS_AREAS: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'areacontacts/',
    description: 'List of Contact Areas',
  },
  CENTER_DATA_SOURCE_TYPES: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'datasourcetypes/',
    description: 'Center Data Source Types related data content',
  },
  KENNELS_LIST: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'kennels/',
    description: 'List of kennels list',
  },
  EMPLOYEES_CENTERS_LIST: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'employees/',
    description: 'List of employees centers list',
  },
  EMPLOYEES_CENTERS_LIST_DELETE: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'employeeid/',
    description: 'List of employees centers list',
  },
  ADDONS_LIST: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'addons/',
    description: 'Reservation type list',
  },
  RESERVATION_LIST: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'reservationtypes/',
    description: 'List of reservations',
  },
  CENTER_IMAGES: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'center/',
    description: 'Center related images content per center',
  },
  CENTER_IMAGE_ADD: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centerphoto/',
    description: 'Add a center image',
  },
  CENTER_IMAGE_DELETE: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'center/',
    description: 'Delete a center image',
  },
  CENTER_CAMERA: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'center/idogcam/',
    description: 'Center camera operations',
  },
  CENTER_CAMERA_SUGGESTED: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'center/suggested/idogcam/',
    description: 'Center suggested camera Data',
  },
  ACCESS_MANAGEMENT: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: 'emp/search',
    description: 'Access Management Data',
  },
  ACCESS_ACL_GROUPS: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: 'acl/groups',
    description: 'Access acl groups data',
  },
  ACL_GROUP_LIST: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: 'acl/groups/',
    description: 'Access Group List',
  },
  ACL_GROUP_GRANTS: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: 'acl/grants/',
    description: 'Access Group Grants',
  },
  ACL_RESOURCES: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: 'acl/resources/',
    description: 'Access Management Resources',
  },
  ACL_USER: {
    apiFamily: 'user/',
    version: 'v1/',
    apiSegment: 'emp/user',
    description: 'Access Add User',
  },
  CUSTOMERS_LIST: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'employee/search/customer',
    description: 'List of Customers',
  },
  CONNECTED_USER: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'employee/customer/',
    description: 'Detail of Customer',
  },
  CRM_CUSTOMER_DETAIL_USER: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'employee/user/',
    description: 'Connected Care - Customer Details - User',
  },
  CRM_CUSTOMER_DETAIL_GLOBAL_CUSTOMER: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'employee/customer/',
    description: 'Connected Care - Customer Details - Global Customer',
  },
  CRM_CUSTOMER_DETAIL_GLOBAL_PET: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'employee/pet/',
    description: 'Connected Care - Customer Details - Global Pets',
  },
  CRM_CUSTOMER_GLOBAL_PET_PATCH: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'admin/pet/',
    description: 'Customer - Pet Admin Edit',
  },
  CRM_CUSTOMER_GLOBAL_PET: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'pets/',
    description: 'Connected Care - Customer Details - Global PET',
  },
  CRM_CUSTOMER_DETAIL_CENTER_CUSTOMER: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'employee/centercustomer/',
    description: 'Connected Care - Customer Details - Center Customer',
  },
  CRM_CUSTOMER_DETAIL_CENTER_PET: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'employee/centerpet/',
    description: 'Connected Care - Customer Details - Center Pet',
  },
  CRM_CUSTOMER_DETAILS: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'employee/customer/',
    description: 'Connected Care - Customer Details',
  },
  CRM_USER_EXPERIENCE: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'employee/customer/',
    description: 'Connected Care - User Experience',
  },
  CRM_CUSTOMER_DETAILS_PUT: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'admin/user/',
    description: 'Connected Care - Customer Details - Edit',
  },
  YOURGI_SERVICES: {
    apiFamily: 'app/',
    version: 'v1/',
    apiSegment: 'center/',
    description: 'Connected Care - Center Yourgi Services',
  },
  YOURGI_SERVICES_RE_SYNC: {
    apiFamily: 'app/',
    version: 'v1/',
    apiSegment: 'center/',
    description: 'Connected Care - Center Yourgi Services Re-Sync',
  },
  YOURGI_SERVICE_LODGINGS: {
    apiFamily: 'app/',
    version: 'v1/',
    apiSegment: 'center/',
    description: 'Connected Care - Center Yourgi Services Lodgings',
  },
  CENTER_SPECIES: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'center/',
    description: 'Connected Care - Center Species',
  },
  YOURGI_SERVICE_ADDONS: {
    apiFamily: 'app/',
    version: 'v1/',
    apiSegment: 'center/',
    description: 'Connected Care - Center Yourgi Services Addons',
  },
  CENTER_PREBOARDING_CHECKLIST: {
    apiFamily: 'app/',
    version: 'v1/',
    apiSegment: 'preboarding/question/',
    description: 'Center Preboarding Checklist Template',
  },
  CENTER_PREBOARDING_QUESTION_DELETE: {
    apiFamily: 'app/',
    version: 'v1/',
    apiSegment: 'preboarding/delete/question/',
    description: 'Center Preboarding Checklist Delete Question',
  },
  CENTER_MARKETING_YOURGI_SERVICES: {
    apiFamily: 'app/',
    version: 'v1/',
    apiSegment: 'appointmenttypes/',
    description: 'Center Marketing - Yourgi Services',
  },
  DVM_EMPLOYEE_GET: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'dvm/employees/',
    description: 'get dvm employees',
  },
  DVM_CREATE_MONTHLY_REPORT_GET: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'dvm/monthly',
    description: 'Create Monthly Report',
  },
  DVM_EMPLOYEE_BONUS_GET: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'dvm/bonus',
    description: 'Calculate Bonus',
  },
  DVM_EMPLOYEE_POST: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'dvm/employees',
    description: 'create dvm employees',
  },
  DVM_EMPLOYEE_UPDATE_POST: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'dvm/employees/update',
    description: 'Update dvm employees',
  },
  DVM_RVD_GET: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'dvm/rvd',
    description: 'create dvm employees',
  },
  DVM_PRODUCTION_POST: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'dvm/productionhistory',
    description: 'Update Production',
  },
  CENTER_ONBOARDING: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'onboarding-activities/',
    description: 'Center Onboarding Add',
  },
  CENTER_IT_NOTES: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'centeritnotes/',
    description: 'Center IT notes data',
  },
  CENTER_FILES: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'file/',
    description: 'Center file',
  },
  CENTER_IT_VENDORS: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'itvendor/',
    description: 'Center IT Vendors',
  },
  DELETE_IT_VENDOR: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'itvendor/delete/',
    description: 'Delete Center IT Vendor',
  },
  VENDOR_INFO: {
    apiFamily: 'center/',
    version: 'v1/',
    apiSegment: 'vendor/',
    description: 'Vendor Info',
  },
  CUSTOMER_CENTER_SPECIES: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'center/${centerId}/species',
    description: 'Customer - Center species',
  },
  CUSTOMER_CENTER_BREEDS: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'center/${centerId}/breeds',
    description: 'Customer - Center breeds',
  },
  CUSTOMER_SPECIES: {
    apiFamily: 'customer/',
    version: 'v1/',
    apiSegment: 'species',
    description: 'Customer - Center breeds',
  },
  DVM_MASTERCLASSITEMS: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'itemmasterclasses/',
    description: 'item master classes',
  },
  DVM_INVOICE_OPTIONS: {
    apiFamily: 'operations/',
    version: 'v1/',
    apiSegment: 'invoiceOptions/',
    description: 'invoice options',
  },
  USER_LOYALTY: {
    apiFamily: 'loyalty/',
    version: 'v1/',
    apiSegment: 'emp/user/',
    description: 'User Loyalty',
  },
  LOYALTY_TIERS: {
    apiFamily: 'loyalty/',
    version: 'v1/',
    apiSegment: 'emp/loyaltytier',
    description: 'Loyalty Tiers',
  },
}

export function apiUrl(apiName: string, routeParams: { [key: string]: string }): URL
export function apiUrl(apiName: string, routeSegment: string): URL
export function apiUrl(apiName: string, routeParamsOrSegment: { [key: string]: string } | string): URL {
  const apiConfig = appConfig[apiName]
  let apiSegment = apiConfig.apiSegment

  if (typeof routeParamsOrSegment === 'object' && !Array.isArray(routeParamsOrSegment)) {
    // Replace named placeholders in apiSegment
    for (const [key, value] of Object.entries(routeParamsOrSegment)) {
      apiSegment = apiSegment.replace(new RegExp(`\\$\\{${key}\\}`, 'g'), value)
    }
  } else if (typeof routeParamsOrSegment === 'string' || typeof routeParamsOrSegment === 'number') {
    // Handle a single route segment
    apiSegment += routeParamsOrSegment
  }

  return new URL(BASE_URL + apiConfig.apiFamily + apiConfig.version + apiSegment)
}
